import { Storage } from "@aws-amplify/storage";
import { useEffect, useState } from "react";
import { useKeyedState } from "./use-keyed-state";

export namespace useS3Url {
    /**
     * {@link useS3Url} 関数の結果値の型
     */
    export interface Result {
        /** URL 作成中フラグ */
        readonly loading: boolean;
        /** URL */
        readonly url: string | undefined;
    }
}

export function useS3Url(key: string | undefined): useS3Url.Result {
    const [url, setUrl] = useState<string>();
    const [loading, setLoading] = useKeyedState(key ?? "", true);

    useEffect(() => {
        if (key === undefined) {
            setUrl(undefined);
            setLoading(false);
            return undefined;
        }

        setLoading(true);
        const promise = Storage.get(key, { download: false });

        promise.then(
            (url) => {
                setUrl(url);
                setLoading(false);
            },
            (error: unknown) => {
                if (Storage.isCancelError(error)) {
                    return;
                }
                setUrl(undefined);
                setLoading(false);
                console.error(error);
            },
        );

        return () => {
            Storage.cancel(promise);
        };
    }, [key, setLoading]);

    return { loading, url };
}
